.BlogLayout{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    .topBox{
        width: 100%;
        height: 200px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        padding: 10px;
        position: relative;
        overflow: hidden;
        display: flex;
        .catBtn{
            border-radius: 0;
            position: absolute;
            top: 20px;
            left: 2px;
            min-width: 130px;
            color: white;
            font-size: 12px;
            width: 130px;
            height: 30px;
            background: var(--secondary-color);
            
        }
        .content{
            margin-top: auto;
            p{
                font-size: 13px;
                color: white;
                margin-bottom: auto;
            }
            .date{
                font-size: 11px;
                color: white;
                margin-bottom: auto;
            }
        }
    }
    .contentBox{
        background: white;
        padding: 10px;
        .title{
            font-size: 13px;
            color: var(--primary-color);
            line-height: 20px;
            margin-bottom: 20px;
        }
        .btnReadmore{
            color: var(--secondary-color);
            font-size: 16px;
            font-weight: bold;
        }
    }
}