.Counter{
    background-blend-mode: overlay;
    height: 600px;
    display: flex;
    align-items: center;
    .BoxWrapper{
        .box{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img{
                width: 70px;
            }
            .title{
                font-size: 38px;
                color: white;
                margin-top: 15px;
            }
            .subtitle{
                font-size:22px;
                color: white;
            }
        }
    }
}

@media screen and (max-width:768px){
    .Counter{
        height: 100%;
        background-repeat: no-repeat !important;
        .row > div{
            margin-top: 30px;
        }
    }
}