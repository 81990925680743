.AboutHomeWidget{
    margin-top: 80px;
    .imgBox{
        img{
            width: 100%;
        }
    }
    .aboutmsg{
        position: relative;
        .bgLogo{
            position: absolute;
            z-index: -1;
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: 400px;
            }
        }
        .leftQuate{
            position: absolute;
            top: 0px;
            transform: translateY(-30px);
            filter: drop-shadow(-2px 2px 1px #d0d0d0);
        }
        .rightQuate{
            position: absolute;
            bottom: 0px;
            right: 0;
            transform: translateY(30px);
            filter: drop-shadow(-2px 2px 1px #d0d0d0);
        }
        .content{
            font-size: 16px;
            line-height: 30px;
        }
    }
}

@media screen and (max-width:600px){
    .AboutHomeWidget{
    margin-top: 40px;
    .aboutmsg{
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .leftQuate,.rightQuate{
       font-size: 20px;
    }
}

}