.BoxSlider{
    overflow: hidden;
    margin-top: 20px;
    padding: 40px 40px 0px 40px;
    .imgBox{
        position: relative;
        display: flex;
        align-items: center;
        height: 450px;
        background-blend-mode: overlay;
        overflow: hidden;
        border-radius: 10px;
        background-size: cover !important;
        background-repeat: no-repeat;
        .content{
            max-width: 500px;
            button{
                min-width: 120px;
                width: 130px;
                height: 40px;
                background: white;
                color: var(--secondary-color);
            }
        }
        img{
            width: 100%;
        }
        .content{
            position: absolute;
            left: 80px;
            color: white;
        }
    }
    .boxContainer{
        min-width: calc(100% - 100px);
        width: 800px;
        height: 120px;
        overflow: hidden;
        margin: auto;
        border-radius: 10px;
        .col{
            padding: 0;
        }
        transform: translateY(-50px);
        .col:not(:last-child){
            border-right: 1px solid #000;
        }
        button{
            min-width: 100px;
            flex-direction: column;
            width: 100%;
            margin: 0;
            border-radius: 0;
            gap: 10px;
            height: 120px;
            background: var(--primary-color);

            &.active{
                background: var(--secondary-color);
            }
            img{
                width: 40px;
            }
            .title{
                font-size: 13px;
                color: white;
                font-weight: bold;
            }
        }
    }
}


// mobile responsive
@media screen and (max-width:600px){
    .BoxSlider{
        padding-left: 0;
        padding-right: 0;
        .imgBox{
            height: 300px;
            padding: 0;
            .content{
                
            }
        }
        .boxContainer{
            display: none;
        }
    }
}