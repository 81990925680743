.Innovation{
    .BoxVision{
        background-color: #F1F2F9;
        padding: 30px;
        border-radius: 20px;
        margin-top: 20px;
        .row{
            align-items: center;
            .title{
                margin-bottom: 20px;
            }
            .imgBox{
                display: flex;
                flex-direction: column;
                &:first-child{
                    margin-left: auto;
                }
                img{
                    width: 250px;
                }
            }
        }
        // doubleImgBox
        .doubleImgBox{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
            img{
                width: 250px;
            }
            :first-child{
                transform: translateY(-70px);
            }
            :nth-child(3){
                transform: translateY(-70px);
            }
        }
    }
}


// media
@media screen and (max-width:768px){
    .Innovation{
        .BoxVision{
            background-color: #F1F2F9;
            padding: 30px;
            border-radius: 20px;
            margin-top: 20px;
            .row{
                .imgBox{
                    margin-top: 30px;
                    img:first-child{
                        margin-left: auto;
                    }
                    img{
                        width: 150px;
                    }
                }
            }
            // doubleImgBox
            .doubleImgBox{
                margin-bottom: 30px;
                gap: 5px;
                img{
                    width: 150px;
                }
                :first-child{
                    transform: translateY(0);
                }
                :nth-child(3){
                    transform: translateY(0);
                }
            }
        }
    }
}