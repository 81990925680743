.MilestoneGraph{
    width: 100%;
    height: auto;
    position: relative;
    margin: 100px auto;
    position: relative;
    &::before{
        content: "";
        min-width: 10px;
        max-width: 50px;
        width: 10px;
        height: 100%;
        background: var(--secondary-color);
        position: absolute;
        left: 50%;
        top: -5%;
        border-radius: 30px;
        transition: width .3s ease-in-out;
    }
    .boxContainer:nth-child(odd){
        left: 50%;
        flex-direction: row-reverse;
        .circleContainer{
            margin-left: 0;
            margin-right: 40px;
        }
    }
    .boxContainer{
        width: 50%;
        left: 0;
        position: relative;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .boxWrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            flex-direction: column;
            font-size: 11px;
            text-align: center;
            .imgwrapper{
                display: flex;
                gap: 20px;
                img{
                    width: 120px;
                }
            }
        }
        .line{
            width: 40%;
            border: 6px solid var(--secondary-color);
            outline: none;
            opacity: 1;
        }
        .circleContainer{
            margin-left: 40px;
            width: 80px;
            min-width: 80px;
            height: 80px;
            border-radius: 50%;
            border: 6px solid var(--primary-color);
            color: #000000;
            font-size: 18px;
        }
        img{
            width: 200px;
        }
    }

}

// media
@media screen and (max-width: 768px) {
    .MilestoneGraph{
        
       
        .boxContainer:nth-child(odd){
            left: 50%;
            flex-direction: row-reverse;
            .circleContainer{
                margin-left: 0;
                margin-right: 0px;
            }
        }
        .boxContainer{
            width: 50%;
            left: 0;
            position: relative;
            margin-top: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            .boxWrapper{   
                gap: 5px;
                .imgwrapper{
                    gap: 5px;
                    img{
                        width: 60px;
                    }
                }
            }
            .circleContainer{
                min-width: 40px;
                height: 40px;
                font-size: 8px;
            }
            img{
                width: 100px;
            }
        }
    
    }
}