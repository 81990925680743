@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --primary-color: #0177A3;
    --secondary-color: #F87405;
    --tertiary-color: #F1F2F9;
    --dot-bg-color: #F1F2F9;
    --dark-color: #000000;
}

#root{
    overflow: hidden;
}

ul,li,h1,h2,h3,h4,h5,p{
    margin: 0;
    padding: 0;
}

li{
    list-style-type: none;
}

a{
    text-decoration: none;
}

body{
    font-family: "Inter", sans-serif;
}

.container-default{
    padding: 0px 15px;
}

.primaryBtn{
    min-width: 150px !important;
    width: 150px;
    background: var(--secondary-color) !important;
    color: white !important;
    height: 40px;
    border-radius: 30px !important;
    box-shadow: 0 10px 12px -9px black;
}

.headingTitle{
    margin-top: 40px;
    font-size: 35px;
    text-align: center;
}