.PageNotFound{
    background: rgba(247,249,251,255);
    height: 600px;
    margin-bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .content{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        .number{
            font-size: 100px;
            color: var(--primary-color);
        }
        .title{
            color: var(--secondary-color);
        }
    }
}