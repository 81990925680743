.TopBar{
    background: var(--primary-color);
    color: white;
    padding: 5px 0;
    .tel{
        display: flex;
        align-items: center;
        gap: 20px;
        .title{
            a{
            color: white;
            font-size: 14px;
            cursor: pointer;
            text-decoration: none;
        }
        }
    }
}