.About{    
    .contentBox{
        width: 100%;
        p{
            font-size: 15px;
            color: rgba(0,0,0,.7);
            line-height: 30px;
        }
        .biggerText{
            font-size: 25px;
            font-weight: 800;
        }
    }
    .doubleImgBox{
        display: flex;
        flex-direction: column;
        img{
            width: 200px;
        }
        :first-child{
            margin-left: auto;
        }
    }
    .imgBox{
        img{
            width: 100%;
            height: 100%;
        }
    }
    // sectionFocus
    .sectionFocus .row{
        display: flex;
        align-items: center;
        background: #F1F2F9;
        padding: 20px;
        border-radius: 10px;
        height: 400px;
    }
}

// media
@media screen and (max-width: 768px) {
    .About{
        .sectionStength{
           .contentBox{
            margin-bottom: 30px;
           }
        }
        .sectionFocus .row{
            display: flex;
            align-items: center;
            background: #F1F2F9;
            padding: 20px;
            border-radius: 10px;
            height: auto;
        }
    }
}