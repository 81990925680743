.NewsRoomPage{
    .contentWrapper{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 20px;
    }
    .pagination{
        margin-top: 60px;
        display: flex;
        justify-content: center;
    }
    // BtnWrapper
    .BtnWrapper{
        width: calc(100vw - 200px);
        margin: auto;
        margin-bottom: 40px;
        ul.menus{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            flex-wrap: nowrap;
            height: 40px;
            li.menu{
                list-style: none;
                flex: 1 0 0;
                height: 100%;
                &.active{
                    button{
                        background: var(--secondary-color);
                    }
                }
                button{
                    color: white;
                    background: var(--primary-color);
                    width: 100%;
                    border-radius: 0;
                    height: 100%;
                    &:hover{
                        background: var(--secondary-color);
                    }
                }
            }
        }
    }
}

// media
@media screen and (max-width:768px){
    .NewsRoomPage{

    .BtnWrapper{
        width: 100% !important;
        overflow: scroll;
        &::-webkit-scrollbar{
            height: 0;
        }
        
        li.menu{
            button{
                font-size: 10px;
            }
        }
    }
    // contentWrapper
    .contentWrapper{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 10px;
    }
    }
}