.HomeSlider{
    padding-bottom: 20px;
    .slider{
        .item{
            img{
                width: 100%;
            }
        }
        .slick-arrow{
            &.slick-next{
                right: 25px !important;
            }
            &.slick-prev{
                left: 25px !important;
                z-index: 100;
            }
        }
    }
}