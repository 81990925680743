.Footer{
    background: #000;
    padding: 50px 20px;
    margin-top: 20px;
    .aboutWidget{
        .Logo{
            width: 200px;
            height: auto;
            margin-bottom: 10px;
        }
        .content{
            p{
                font-size: 12px;
                color: white;
                line-height: 25px;
            }
        }
        .SocialMediaWidget{
            margin-top: 20px;
            .title{
                font-size: 15px;
                color: white;
            }
            button{
                min-width: 50px;
                height: 50px;
                width: 50px;
                svg{
                    font-size: 30px;
                }
            }
        }
    }
    .navWidget{
        text-align: center;
        .widgetTitle{
            font-size: 15px;
            color: white;
            margin-bottom: 18px;
            position: relative;
        }
        .menus{
            li{
                &.active{
                    background: var(--primary-color);
                    border-radius: 5px;
                }
                button{
                    font-size: 11px !important;
                    color: white;
                    font-weight: 500;
                    width: 100%;
                    &:hover{
                        background: var(--primary-color);
                    }
                }
            }
        }
    }
    .copyright{
        border-top: 1px solid rgba(249, 249, 249, 0.472);
        width: 100%;
        margin-top: 30px;
        text-align: center;
        color: white;
        opacity: .5;
        padding-top: 10px;
        font-size: 12px;
    }
}

@media screen and (max-width:600px){
    .Footer{
        .row > .col-4,.row > .col{
            width: 100%;
        }
    }
}