.Warehouse{
    .contentBox{
        p{
            font-size: 15px;
            color: rgba(0,0,0,.7);
            line-height: 30px;
        }
    }
    .gallery{
        margin: 40px 0;
    }
}

// Gallery
@media screen and (max-width:678px){
    .Warehouse{
        .gallery{
            margin: 40px 0;
            .ReactGridGallery_tile-viewport{
                height: 100px !important;
                img{
                    height: 100px !important;
                }
         }
        }
    }
}