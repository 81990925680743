.SocialMediaWidget{
    display: flex;
    align-items: center;
    gap: 20px;
    .title{
        font-size: 14px;
    }
    button{
        min-width: 30px;
        width: 30px;
        height: 20px;
        background: transparent !important;
        padding: 0 !important;
        svg{
            color: white;
            font-size: 20px;
        }
    }
}