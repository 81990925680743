.Contact{
    .title{
        margin-top: 40px;
        font-size: 35px;
        text-align: center;
    }
    .cardBox{
        margin-top: 40px;
        padding: 25px;
        width: 100%;
        background: #F1F2F9;
        border-radius: 10px;
        .title{
            margin-top: 0;
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 20px;
        }
        .form{
            display: flex;
            flex-direction: column;
            gap: 15px;
            .checkbox-group{
                display: flex;
                align-items: center;
                gap: 20px;
                label{
                    font-size: 14px;
                }
            }
            button{
                margin-top: 20px;
            }
        }
        // detailsbox
        &.detailsbox{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: calc(100% - 40px);
            .addressBox{
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 10px;
                svg{
                    font-size: 30px;
                }
            }
        }
    }
    // Map
    .Map{
        margin-top: 40px;
    }
}

// media
@media screen and (max-width: 768px) {
    .Contact{
        .detailsbox{
            align-items: start !important;
            justify-content: start !important;
        }
    }
}