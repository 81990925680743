.Milestones{
    .content{
        p{
            color: rgba(0,0,0,.6);
        }
    }
    .logoContainer{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 20px;
        img{
            width: 150px;
        }
    }
}

// media
@media screen and (max-width:768px){
    .Milestones{
        .logoContainer{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            img{
                width: 100px;
            }
        }
    }
}