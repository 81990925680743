.About2{
    background: #F1F2F9;
    padding: 20px;
    .content-box{
        padding: 10px;
        .title{
            color: var(--primary-color);
            font-size: 22px;
            margin-bottom: 10px;
        }
        .content{
            p{
                font-size: 16px;
                color: #000;
                line-height: 28px;
                text-align: justify;
            }
        }
    }
    .imgBox{
        display: flex;
        flex-direction: column;
        img{
            width: 200px;
        }
        .right{
            margin-left: auto;
        }
    }
}