.NewsRoom{
    margin: 40px 0;
    .container > .title{
        text-align: center;
        color: var(--primary-color);
    }
    .row{
        margin: 30px 0;
    }
}

// mobile
