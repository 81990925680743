.BreadCrumb{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .imgWrapper{
        width: 100%;
        height: 400px;
        overflow: hidden;
        img{
            width: 100%;
        }
    }
}

// media
@media screen and (max-width: 768px) {
    .BreadCrumb{
        .imgWrapper{
            height: 150px;
            overflow: hidden;
        }
    }
}