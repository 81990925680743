.LogoSlider{
    margin-top: 40px;
    overflow: hidden;
    .Slider{
        .item{
            img{
                width: auto;
                height: 50px;
            }
        }
        .slick-arrow{
            z-index: 99;
            &.slick-next{
                right: 25px !important;
            }
            &.slick-prev{
                left: 25px !important;
            }
        }
    }
}