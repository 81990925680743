.About3{
    overflow: hidden;
    padding: 20px;
    .imgBox{
        display: flex;
        flex-direction: column;
        img{
            width: 200px;
        }
        .right{
            margin-left: auto;
        }
    }
    .content-box{
        position: relative;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title{
            color: var(--primary-color);
            font-size: 22px;
            margin-bottom: 10px;
        }
        .content{
            p{
                font-size: 16px;
                color: #000;
                line-height: 28px;
                text-align: justify;
                line-height: 30px;
            }
        }
        .Logo{
            position: absolute;
            z-index: -1;
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                width: 400px;
            }
        }
    }
}

@media screen and (max-width:600px) {
    .About3{
        padding: 20px;
        margin-top: 20px;
        .imgBox{
            display: flex;
            flex-direction: column;
            gap: 20px;
            img{
                width: 200px;
            }
            .right{
                margin-left: auto;
            }
        }
        .content-box{
            margin-top: 40px;
            position: relative;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title{
                color: var(--primary-color);
                font-size: 22px;
                margin-bottom: 10px;
            }
            .content{
                p{
                    font-size: 16px;
                    color: #000;
                    line-height: 28px;
                    text-align: justify;
                    line-height: 30px;
                }
            }
            .Logo{
                position: absolute;
                z-index: -1;
                width: 100%;
                display: flex;
                justify-content: center;
                img{
                    width: 400px;
                }
            }
        }
    }
}