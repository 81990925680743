.About4{
    background: #F1F2F9;
    padding: 20px;
    .content-box{
        padding: 10px;
        .title{
            color: var(--primary-color);
            font-size: 22px;
            margin-bottom: 10px;
        }
        .content{
            p{
                font-size: 16px;
                color: #000;
                line-height: 28px;
                text-align: justify;
            }
        }
    }
    .imgBox{
        display: flex;
        flex-wrap: wrap;
        img{
            width: 200px;
        }
        img:nth-child(1){
            transform: translateY(-60px);
        }
        img:nth-child(3){
            transform: translateY(-50px);
        }
        .right{
            margin-left: auto;
        }
    }
}

// media mobile
@media screen and (max-width: 768px) {
    .About4{
        .content-box{
            margin-bottom: 50px;
        }
        .imgBox{
            img{
                width: 160px;
            }
            img:nth-child(1){
                transform: translateY(0px);
            }
            img:nth-child(3){
                transform: translateY(0px);
            }
        }
    }
}