.Map{
    
}

@media screen and (max-width: 600px){
    .Map{
        iframe{
            height: 200px !important;
        }
    }
}