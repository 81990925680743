.NavBar{
    width: 100%;
    display: flex;
    align-items: center;
    ul.menu{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 5px;
        li{
            button{
                min-width: 80px;
                background: transparent;
                color: var(--secondary-color);
                font-size: 14px;
            }
            &.active{
                button{
                    color: var(--primary-color);
                }
            }
        }
    }
    // menubar
    .menubar{
        display: none;
    }
}

// media
@media screen and (max-width: 768px) {
    .NavBar{
        display: flex;
        justify-content: end;
        ul.menu{
            display: none;
        }
        .menubar{
            display: flex;
            justify-content: end;
            min-width: 50px;
            width: 50px;
            height: 50px;
            border-radius: 10px;
            align-items: center;
            justify-content: center;
                svg{
                    font-size: 25px;
                    color: var(--primary-color);
                }
                &.active svg{
                    color: var(--secondary-color);
                }
        }
    }
    .sidebar{
        .MuiPaper-root{
            margin: 10px;
            border-radius: 10px;
        }
        ul.menu{
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: end;
            gap: 5px;
            li{
                width: 100%;
                button{
                    width: 100%;
                    background: transparent;
                    color: var(--secondary-color);
                    font-size: 16px;
                }
                &.active{
                    button{
                        color: var(--primary-color);
                        font-weight: bold;
                    }
                }
            }
        }
   }
}